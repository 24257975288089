import React from "react";
import "./Header.css";
import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { ExternalLink } from "react-external-link";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header-container">
      <Link to="./" className="header-title-link">
        <div className="header-title">Manotick Main Pharmacy</div>
      </Link>
      <div className="header-phone-add">
        <div className="header-phone">
          <div>
            <FaPhoneAlt className="header-icon1" />
          </div>
          <div className="header-phone-fax">
            <div className="header-phone-num">Tel: 613-692-2999</div>
            <div className="header-fax">Fax: 613-692-3056</div>
          </div>
        </div>
        <div className="header-address">
          <div className="header-address-icon">
            <ExternalLink href="https://www.google.com/maps/place/Manotick+Main+Pharmacy+-+Rx+Pharmachoice/@45.2241607,-75.6857751,17z/data=!4m5!3m4!1s0x4ccde5d7ab72289b:0x95468bc4bc361927!8m2!3d45.2241607!4d-75.6835864">
              <FaMapMarkerAlt className="header-icon2" />
            </ExternalLink>
          </div>
          <div className="header-address-txt">
            5556 Manotick Main St Unit 5, Manotick, Ontario
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
