import { useState, useEffect } from "react";
import "./Forms.css";
import LoadingSpinner from "./LoadingSpinner";
import SubmitMessageBox from "./SubmitMessageBox";
import { Helmet } from "react-helmet";

const NewPrescription = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "New Prescription | Manotick Main Pharmacy";
  }, []);

  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNum: "",
    dateOfBirth: "",
    address: "",
    postalCode: "",
    email: "",
    notes: "",
  });
  const [prescriptionFile, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsgBox, setShowMsgBox] = useState(false);
  const [msgBoxHeader, setMsgBoxHeader] = useState("");
  const [msgBoxMsg, setMsgBoxMsg] = useState("");

  const handleFileUploadChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormDetails((formDetails) => ({
      ...formDetails,
      firstName: "",
      lastName: "",
      phoneNum: "",
      dateOfBirth: "",
      address: "",
      postalCode: "",
      email: "",
      notes: "",
    }));
    setFile("");
    document.getElementById("prescFile").value = "";
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // open the request with the verb and the url
    xhr.open(
      "POST",
      "https://manotickmainpharmacy.ca/pharmacy-server/execNewPresc.php"
    );

    let formData = new FormData();
    formData.append("firstName", formDetails.firstName);
    formData.append("lastName", formDetails.lastName);
    formData.append("phoneNum", formDetails.phoneNum);
    formData.append("dateOfBirth", formDetails.dateOfBirth);
    formData.append("address", formDetails.address);
    formData.append("postalCode", formDetails.postalCode);
    formData.append("email", formDetails.email);
    formData.append("notes", formDetails.notes);

    formData.append("prescriptionFile", prescriptionFile);

    xhr.timeout = 20000;
    xhr.ontimeout = function (error) {
      console.log(
        "Timed out while submitting New Prescription request. ",
        error
      );
      setIsLoading(false);
      setMsgBoxHeader("Error!");
      setMsgBoxMsg(
        "Could not submit your request. Please try again later or contact us on this phone number 613-692-2999."
      );
      setShowMsgBox(true);
    };

    xhr.onerror = function (error) {
      console.log(
        "An error occured while submitting New Prescription request. ",
        error
      );
      setIsLoading(false);
      setMsgBoxHeader("Error!");
      setMsgBoxMsg(
        "Could not submit your request. Please try again later or contact us on this phone number 613-692-2999."
      );
      setShowMsgBox(true);
    };

    xhr.onreadystatechange = function () {
      //Call a function when the state changes.
      if (xhr.readyState === 4 && xhr.status === 200) {
        setIsLoading(false);
        setMsgBoxHeader("Submitted Successfully!");
        setMsgBoxMsg("NOTE: Allow 24 hours to fill your prescription.");
        setShowMsgBox(true);
        resetForm();
      }
    };
    // send the request
    xhr.send(formData);
  };

  const closeDialog = () => {
    // console.log("showDialog: ", showMsgBox);
    setShowMsgBox(false);
  };

  const maxDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div className="form-container">
      <Helmet>
        <title>New Prescription | Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="Upload your prescription, we'll prepare it and contact you."
        />
      </Helmet>
      <div className="forms-title">
        <h2 className="forms-title-txt">New Prescription</h2>
      </div>

      <div className="row-container">
        {<LoadingSpinner show={isLoading} />}
        {
          <SubmitMessageBox
            show={showMsgBox}
            okClickHandler={closeDialog}
            msgBoxHeader={msgBoxHeader}
            msgBoxMsg={msgBoxMsg}
          />
        }

        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="column">
              <label className="col-label required">First Name</label>
              <input
                type="text"
                className="col-input"
                name="firstName"
                value={formDetails.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="column">
              <label className="col-label required">Last Name</label>
              <input
                type="text"
                className="col-input"
                name="lastName"
                value={formDetails.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label className="col-label required">Phone Number</label>
              <input
                type="text"
                className="col-input"
                pattern="^[0-9]{10}$"
                name="phoneNum"
                value={formDetails.phoneNum}
                onChange={handleChange}
                title="Please enter your 10 digit phone number"
                required
              />
            </div>
            <div className="column">
              <label className="col-label required">
                Patient's Date of Birth
              </label>
              <input
                type="date"
                name="dateOfBirth"
                className="col-input col-input-date"
                value={formDetails.dateOfBirth}
                onChange={handleChange}
                max={maxDate()}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label className="col-label required">Address</label>
              <input
                type="text"
                className="col-input"
                name="address"
                value={formDetails.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="column">
              <label className="col-label">Postal code</label>
              <input
                type="text"
                className="col-input"
                // pattern=""
                name="postalCode"
                value={formDetails.postalCode}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row-email">
            <div className="column-email">
              <label className="col-label"> Email Address</label>
              <input
                type="email"
                className="col-input"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="enter a valid email."
                name="email"
                value={formDetails.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="column-text-area">
              <label className="col-label-textarea">Notes</label>
              <textarea
                className="col-input-textarea"
                rows={4}
                name="notes"
                value={formDetails.notes}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label className="col-label required">Prescription </label>
              <input
                type="file"
                className="input-file"
                name="prescription"
                id="prescFile"
                accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                onChange={handleFileUploadChange}
                required
              />
            </div>
          </div>

          <div className="submit-btn-row">
            <input
              type="submit"
              value="Submit"
              className="submit-btn"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPrescription;
