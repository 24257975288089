import "./Home.css";
import { useEffect } from "react";
import { BsCreditCardFill } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { FaShuttleVan } from "react-icons/fa";
import { ExternalLink } from "react-external-link";
import HomePrescBox from "./HomePrescBox";
import RxPharmaImg from "./images/Rx_Pharma.jpg";
import NewPrescImage from "./images/New_Prescription.jpg";
import RefillPrescImg from "./images/Refill_Prescription.jpg";
import TransferPrescImg from "./images/Transfer_Prescription.jpg";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Manotick Main Pharmacy";
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="Manotick Main Pharmacy is a locally owned, independent pharmacy located right here in
            Manotick. The pharmacist and owner, Jayal (Jay), has over 9 years of
            practicing experience, so you can rest assured that all of the
            services are reliable."
        />
      </Helmet>
      <div className="images-options-container">
        <img
          src={RxPharmaImg}
          alt="Manotick Main Pharmacy"
          className="home-image"
        />
        <div className="prescription-container">
          <HomePrescBox
            btnName={"NEW PRESCRIPTION"}
            className="presc-option1"
            img={NewPrescImage}
            linkTo="./newPrescription"
          />
          <HomePrescBox
            btnName={"REFILL PRESCRIPTION"}
            className="presc-option2"
            img={RefillPrescImg}
            linkTo="./refillPrescription"
          />
          <HomePrescBox
            btnName={"TRANSFER PRESCRIPTION"}
            className="presc-option3"
            img={TransferPrescImg}
            linkTo="./transferPrescription"
          />
        </div>
      </div>

      <div className="mid1">
        <span className="mid1-top">AGE HAS IT'S PRIVILEGES </span>
        <span className="mid1-bottom">
          <span className="mid1-bottom-one">
            Seniors Save 20% Off Every Wednesday
          </span>
          <span className="mid1-bottom-two"> on regular priced OTC items</span>
        </span>
      </div>

      <div className="mid2">
        <span className="mid2-hdr">Taking care of your health</span>
        <div className="mid2-content">
          <div className="mid2-info-box">
            <div className="mid2-icon-container">
              <BsCreditCardFill className="mid2-icon" />
            </div>
            <div className="mid2-info-title"> We Accept All Drug Plans</div>
            <div className="mid2-info-container">
              <div className="mid2-info">
                We have official account with all drug insurance providers for
                patient's direct billing
                <br />​ We are preferred provider for Coughlin Insurance
              </div>
            </div>
          </div>

          <div className="mid2-info-box">
            <div className="mid2-icon-container">
              <AiOutlineUser className="mid2-icon" />
            </div>
            <div className="mid2-info-title">Locally Owned Pharmacy</div>
            <div className="mid2-info-container">
              <div className="mid2-info">
                Locally owned independent pharmacy proud to serve and support
                our local community
              </div>
            </div>
          </div>

          <div className="mid2-info-box">
            <div className="mid2-icon-container">
              <FaShuttleVan className="mid2-icon" />
            </div>
            <div className="mid2-info-title">Free Medication Delivery </div>
            <div className="mid2-info-container">
              <div className="mid2-info">
                Get your medication at your door step. Free local delivery
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mid3">
        <div className="mid3-hdr">Sign up for our e-flyer & latest update</div>
        <ExternalLink href="https://www.pharmachoice.com/e-flyer-signup-form/?flyer_plan=18&store_code=5807&store_address=5556%20Manotick%20Main%20Street%2C%20Unit%205%2C%20Manotick%2C%20ON%20%2CArray%2CArray%2C&state=Array&store_name=Manotick%20Main%20Pharmacy&phone&store_url=https%3A%2F%2Fwww.pharmachoice.com%2Flocations%2Fmanotick-main-pharmacy%2F">
          <button className="mid3-sign-up-btn">Sign Up</button>
        </ExternalLink>
      </div>
    </div>
  );
};

export default Home;
