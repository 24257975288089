import React, { useState, useEffect } from "react";
import LoadingSpinner from "./LoadingSpinner";
import SubmitMessageBox from "./SubmitMessageBox";
import { Helmet } from "react-helmet";

const RefillPrescription = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Refill Prescription | Manotick Main Pharmacy";
  }, []);

  const [refillFormDetails, setRefillFormDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNum: "",
    email: "",
    rxNum1: "",
    rxNum2: "",
    rxNum3: "",
    rxNum4: "",
    notes: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showMsgBox, setShowMsgBox] = useState(false);
  const [msgBoxHeader, setMsgBoxHeader] = useState("");
  const [msgBoxMsg, setMsgBoxMsg] = useState("");

  const handleChange = (e) => {
    setRefillFormDetails({
      ...refillFormDetails,
      [e.target.name]: e.target.value,
    });
  };

  const resetRefillForm = () => {
    setRefillFormDetails((formDetails) => ({
      ...formDetails,
      firstName: "",
      lastName: "",
      phoneNum: "",
      email: "",
      rxNum1: "",
      rxNum2: "",
      rxNum3: "",
      rxNum4: "",
      notes: "",
    }));
    // setShowMsgBox(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // open the request with the verb and the url
    xhr.open(
      "POST",
      "https://manotickmainpharmacy.ca/pharmacy-server/execRefillPresc.php"
    );

    let formData = new FormData();
    formData.append("firstName", refillFormDetails.firstName);
    formData.append("lastName", refillFormDetails.lastName);
    formData.append("phoneNum", refillFormDetails.phoneNum);
    formData.append("email", refillFormDetails.email);
    formData.append("rxNum1", refillFormDetails.rxNum1);
    formData.append("rxNum2", refillFormDetails.rxNum2);
    formData.append("rxNum3", refillFormDetails.rxNum3);
    formData.append("rxNum4", refillFormDetails.rxNum4);
    formData.append("notes", refillFormDetails.notes);

    xhr.timeout = 20000;
    xhr.ontimeout = function (error) {
      console.log(
        "Timed out while submitting Refill Prescription request.",
        error
      );
      setIsLoading(false);
      setMsgBoxHeader("Error!");
      setMsgBoxMsg(
        "Could not submit your request. Please try again later or contact us on this phone number 613-692-2999."
      );
      setShowMsgBox(true);
    };

    xhr.onerror = function (error) {
      console.log(
        "An error occured while submitting Refill Prescription request. ",
        error
      );
      setIsLoading(false);
      setMsgBoxHeader("Error!");
      setMsgBoxMsg(
        "Could not submit your request. Please try again later or contact us on this phone number 613-692-2999."
      );
      setShowMsgBox(true);
    };

    xhr.onreadystatechange = function () {
      //Call a function when the state changes.
      if (xhr.readyState === 4 && xhr.status === 200) {
        setIsLoading(false);
        setMsgBoxHeader("Submitted Successfully!");
        setMsgBoxMsg("NOTE: Allow 24 hours to fill your prescription.");
        setShowMsgBox(true);
        resetRefillForm();
      }
    };
    // send the request
    xhr.send(formData);
  };

  const closeDialog = () => {
    // console.log("showDialog: ", showMsgBox);
    setShowMsgBox(false);
  };

  return (
    <div className="form-container">
      <Helmet>
        <title>Refill Prescription | Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="Upload your prescription, we'll refill it and contact you."
        />
      </Helmet>
      <div className="forms-title">
        <h2 className="forms-title-txt">Refill Prescription</h2>
      </div>

      <div className="row-container">
        {<LoadingSpinner show={isLoading} />}
        {
          <SubmitMessageBox
            show={showMsgBox}
            okClickHandler={closeDialog}
            msgBoxHeader={msgBoxHeader}
            msgBoxMsg={msgBoxMsg}
          />
        }

        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="column">
              <label className="col-label">First Name</label>
              <input
                type="text"
                className="col-input"
                name="firstName"
                value={refillFormDetails.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="column">
              <label className="col-label">Last Name</label>
              <input
                type="text"
                className="col-input"
                name="lastName"
                value={refillFormDetails.lastName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label className="col-label required">Phone Number</label>
              <input
                type="text"
                className="col-input"
                pattern="^[0-9]{10}$"
                title="Please enter your 10 digit phone number"
                name="phoneNum"
                value={refillFormDetails.phoneNum}
                onChange={handleChange}
                required
              />
            </div>
            <div className="column">
              <label className="col-label"> Email Address</label>
              <input
                type="email"
                className="col-input"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="enter a valid email."
                name="email"
                value={refillFormDetails.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label className="col-label required">RX Number</label>
              <input
                type="text"
                className="col-input"
                name="rxNum1"
                value={refillFormDetails.rxNum1}
                onChange={handleChange}
                required
              />
            </div>
            <div className="column">
              <label className="col-label">RX Number</label>
              <input
                type="text"
                className="col-input"
                name="rxNum2"
                value={refillFormDetails.rxNum2}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label className="col-label">RX Number</label>
              <input
                type="text"
                className="col-input"
                name="rxNum3"
                value={refillFormDetails.rxNum3}
                onChange={handleChange}
              />
            </div>
            <div className="column">
              <label className="col-label">RX Number</label>
              <input
                type="text"
                className="col-input"
                name="rxNum4"
                value={refillFormDetails.rxNum4}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="column-text-area">
              <label className="col-label-textarea">Notes</label>
              <textarea
                className="col-input-textarea"
                rows={4}
                name="notes"
                value={refillFormDetails.notes}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="submit-btn-row">
            <input
              type="submit"
              value="Submit"
              className="submit-btn"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default RefillPrescription;
