import "./Prescriptions.css";
import { useEffect } from "react";
import InfoBox from "./InfoBox";
import NewPrescImg from "./images/New_Prescription.jpg";
import RefillPrescImg from "./images/Refill_Prescription.jpg";
import TransferPrescImg from "./images/Transfer_Prescription.jpg";
import { Helmet } from "react-helmet";

const Prescriptions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Prescriptions | Manotick Main Pharmacy";
  }, []);

  return (
    <div className="prescriptions-container">
      <Helmet>
        <title>Prescriptions | Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="Request new precription, refill your prescription or transfer your prescription from another pharmacy."
        />
      </Helmet>
      <div className="presc-title">
        <p className="presc-title-txt">Prescriptions </p>
      </div>
      <div className="options-container">
        <InfoBox
          btnName={"New Prescription"}
          boxClassName="presc-box"
          imgBoxClass="presc-img-box"
          imgDivClass="option-img"
          imgClass="image"
          img={NewPrescImg}
          linkTo="/newPrescription"
          btnClass="option-btn"
        />

        <InfoBox
          btnName={"Refill Prescription"}
          boxClassName="presc-box"
          imgDivClass="option-img"
          imgClass="image"
          img={RefillPrescImg}
          linkTo="/refillPrescription"
          btnClass="option-btn"
        />

        <InfoBox
          btnName={"Transfer Prescription"}
          boxClassName="presc-box"
          imgDivClass="option-img"
          imgClass="image"
          img={TransferPrescImg}
          linkTo="/transferPrescription"
          btnClass="option-btn"
        />
      </div>
    </div>
  );
};

export default Prescriptions;
