import "./OurServices.css";
import { useEffect } from "react";
import InfoBox from "./InfoBox";
import CompressionStockingImg from "./images/Compression_Stocking.png";
import CompliancePackImg from "./images/Compliance_Pack.png";
import MedicationReviewImg from "./images/Medication_Review.png";
import HomeHealthCareProdsImg from "./images/Home_Health_Care_Prods.png";
import { Helmet } from "react-helmet";

const OurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Our Services | Manotick Main Pharmacy";
  }, []);
  return (
    <div className="our-services-container">
      <Helmet>
        <title>Our Services | Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="We provide Compliance pack, Compression stocking, Medication review and home and health care products, etc."
        />
      </Helmet>
      <div className="our-services-title-container">
        <p className="our-services-title">Our Services</p>
      </div>

      <div className="service-box-container">
        <InfoBox
          serviceName="compliancePack"
          btnName="Compliance Pack"
          boxClassName="service-box"
          imgBoxClass="service-box-img-box"
          imgDivClass="service-box-img-container"
          imgClass="service-box-image"
          img={CompliancePackImg}
          linkTo="/service"
          btnClass="service-box-btn"
          hasIntro
          intro="Consistently adhering to a medication schedule as prescribed by a doctor, though, may be difficult for some people."
          introClass="service-box-intro"
        />

        <InfoBox
          isServiceBox
          serviceName="compressionStocking"
          btnName="Compression Stocking"
          boxClassName="service-box"
          imgBoxClass="service-box-img-box"
          imgDivClass="service-box-img-container"
          imgClass="service-box-rectangle-image"
          img={CompressionStockingImg}
          linkTo="/service"
          btnClass="service-box-btn"
          hasIntro
          intro="Compression stockings are specially made, snug-fitting, stretchy socks that gently squeeze your leg."
          introClass="service-box-intro"
        />

        <InfoBox
          isServiceBox
          serviceName="medicationReview"
          btnName="Medication Review"
          boxClassName="service-box"
          imgBoxClass="service-box-img-box"
          imgDivClass="service-box-img-container"
          imgClass="service-box-image"
          img={MedicationReviewImg}
          linkTo="/service"
          btnClass="service-box-btn"
          hasIntro
          intro="We provide medication review service which enhances your understanding of your medication regimen."
          introClass="service-box-intro"
        />
      </div>

      <div className="service-box-container">
        <InfoBox
          isServiceBox
          serviceName="homeHealthCareProds"
          btnName="Home Health Care Products"
          boxClassName="service-box"
          imgBoxClass="service-box-img-box"
          imgDivClass="service-box-img-container"
          imgClass="service-box-image"
          img={HomeHealthCareProdsImg}
          linkTo="/service"
          btnClass="service-box-btn"
          hasIntro
          intro="We provide wide range of home health care products covering most patient's need."
          introClass="service-box-intro"
        />
      </div>
    </div>
  );
};

export default OurServices;
