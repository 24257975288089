import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Prescriptions from "./components/Prescriptions";
import NewPrescription from "./components/NewPrescription";
import RefillPrescription from "./components/RefillPrescription";
import TransferPrescription from "./components/TransferPrescription";
import OurServices from "./components/OurServices";
import Service from "./components/Service";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import BookNowButton from "./components/BookNowButton";
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Manotick Main Pharmacy</title>
          <meta
            name="description"
            content="Manotick Main Pharmacy is a locally owned, independent pharmacy located right here in
            Manotick. The pharmacist and owner, Jayal (Jay), has over 9 years of
            practicing experience, so you can rest assured that all of the
            services are reliable."
          />
        </Helmet>
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/prescriptions" element={<Prescriptions />} />
          <Route path="/newPrescription" element={<NewPrescription />} />
          <Route path="/refillPrescription" element={<RefillPrescription />} />
          <Route
            path="/transferPrescription"
            element={<TransferPrescription />}
          />
          <Route path="/ourServices" element={<OurServices />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/aboutUs" element={<AboutUs />} />
        </Routes>
        <BookNowButton />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
