import "./HomePrescBox.css";
import { Link } from "react-router-dom";

const HomePrescBox = ({ btnName, linkTo, ...props }) => {
  return (
    <div className={`home-presc-box ${props.className}`}>
      <div className="presc-option-img">
        <img src={props.img} alt={btnName} className="presc-image" />
      </div>
      <Link to={linkTo}>
        <button className="presc-option-btn">{btnName}</button>
      </Link>
    </div>
  );
};

export default HomePrescBox;
