import { ExternalLink } from "react-external-link";
import "./BookNowButton.css";

const BookNowButton = () => {
  return (
    <div>
      <ExternalLink href="https://www.bookmytest.ca/6136922999">
        <button className="booknow-btn">Book Now</button>
      </ExternalLink>
    </div>
  );
};

export default BookNowButton;
