import "./Footer.css";
import { FaPhoneAlt, FaFacebookF, FaMapMarkerAlt } from "react-icons/fa";
import { ExternalLink } from "react-external-link";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-one">
        <p>&copy; 2022 by Manotick Main Pharmacy</p>
      </div>
      <div className="footer-two">
        <ExternalLink href="https://www.google.com/maps/place/Manotick+Main+Pharmacy+-+Rx+Pharmachoice/@45.2241607,-75.6857751,17z/data=!4m5!3m4!1s0x4ccde5d7ab72289b:0x95468bc4bc361927!8m2!3d45.2241607!4d-75.6835864">
          <span className="footer-loc-icon">
            <FaMapMarkerAlt />
          </span>
        </ExternalLink>

        <span className="address">
          5556 Manotick Main St Unit 5, Manotick, Ontario{" "}
        </span>
        <span className="footer-phone-icon">
          <FaPhoneAlt />
        </span>
        <span className="phone-number">Tel: 613-692-2999 </span>
        <span className="fax-number">Fax: 613-692-3056 </span>
        <ExternalLink href="https://www.facebook.com/ManotickMain/">
          <span className="facebook-icon">
            <FaFacebookF />
          </span>
        </ExternalLink>
      </div>
    </div>
  );
};

export default Footer;
