export const navItems = [
  {
    id: 1,
    title: "HOME",
    path: "./",
    cName: "nav-link",
  },
  {
    id: 2,
    title: "PRESCRIPTIONS",
    path: "/prescriptions",
    cName: "nav-link",
  },
  {
    id: 3,
    title: "COMPOUNDING",
    path: "/service",
    cName: "nav-link",
  },
  {
    id: 4,
    title: "OUR SERVICES",
    path: "/ourServices",
    cName: "nav-link",
  },
  {
    id: 5,
    title: "CONTACT US",
    path: "/contactUs",
    cName: "nav-link",
  },
  {
    id: 6,
    title: "ABOUT US",
    path: "/aboutUs",
    cName: "nav-link",
  },
];

export const prescMenuItems = [
  {
    id: 1,
    title: "NEW PRESCRIPTIONS",
    path: "/newPrescription",
    cName: "dropdown-link",
  },
  {
    id: 2,
    title: "REFILL PRESCRIPTIONS",
    path: "/refillPrescription",
    cName: "dropdown-link",
  },
  {
    id: 3,
    title: "TRANSFER YOUR PRESCRIPTIONS",
    path: "/transferPrescription",
    cName: "dropdown-link",
  },
];

export const serviceMenuItems = [
  {
    id: 1,
    title: "Compliance Pack",
    path: "/service",
    cName: "dropdown-link",
    isService: true,
    serviceName: "compliancePack",
  },
  {
    id: 2,
    title: "Compression Stocking",
    path: "/service",
    cName: "dropdown-link",
    isService: true,
    serviceName: "compressionStocking",
  },
];
