import "./AboutUs.css";
import { useEffect } from "react";
import RxPharmaImg from "./images/Rx_Pharma.jpg";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =  "About Us | Manotick Main Pharmacy";
  }, []);

  return (
    <div className="aboutus-container">
      <Helmet>
        <title>About Us | Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="Manotick Main Pharmacy is a locally owned, independent pharmacy located right here in
            Manotick. The pharmacist and owner, Jayal (Jay), has over 9 years of
            practicing experience, so you can rest assured that all of the
            services are reliable."
        />
      </Helmet>
      <div className="aboutus-title">
        <p className="aboutus-title-txt">About Us</p>
      </div>

      <div className="pharmacyinfo-container">
        <div className="pharmacy-image-container">
          <img
            src={RxPharmaImg}
            alt="Manotick Main Pharmacy"
            className="pharmacy-img"
          />
        </div>
        <div className="pharmacy-info">
          <p className="pharmacyinfo-title">About Manotick Main Pharmacy</p>

          <p className="info">
            We are a locally owned, independent pharmacy located right here in
            Manotick. The pharmacist and owner, Jayal (Jay), has over 9 years of
            practicing experience, so you can rest assured that all of the
            services are reliable.
          </p>

          <p className="info">
            You will see Jay almost every day in Pharmacy for patient care and
            would know about medical conditions and medical history of the
            patients, which is hard to achieve in pharmacy where you see
            different Pharmacist every day. Once patient’s file is transferred
            from other Pharmacy by just one simple call from Manotick Main
            Pharmacy, all last filled or on hold medications are transferred to
            Pharmacy.
          </p>

          <p className="info">
            We defined the basic principles of work for our self: accuracy,
            responsibility, reliability and quality service. We are dedicated to
            provide quick medication dispensing, as we know that sometimes you
            need access to your medication quickly.
          </p>

          <p className="info">
            We are proud to serve and support our local community! We serve wide
            variety of healthcare needs such as prescription filling, specialty
            compounding, compliance packaging, compression stocking, home health
            care products, vaccines and many more for the people of the local
            community.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
