import { useState, useEffect } from "react";
import "./ContactUs.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import LoadingSpinner from "./LoadingSpinner";
import SubmitMessageBox from "./SubmitMessageBox";
import { ExternalLink } from "react-external-link";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact Us | Manotick Main Pharmacy";
  }, []);

  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNum: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showMsgBox, setShowMsgBox] = useState(false);
  const [msgBoxHeader, setMsgBoxHeader] = useState("");
  const [msgBoxMsg, setMsgBoxMsg] = useState("");

  const handleChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const resetContactForm = () => {
    setFormDetails((formDetails) => ({
      ...formDetails,
      firstName: "",
      lastName: "",
      email: "",
      phoneNum: "",
      message: "",
    }));
  };

  const onSubmitContact = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // open the request with the verb and the url
    xhr.open(
      "POST",
      "https://manotickmainpharmacy.ca/pharmacy-server/execContactUs.php"
    );

    let formData = new FormData();
    formData.append("firstName", formDetails.firstName);
    formData.append("lastName", formDetails.lastName);
    formData.append("email", formDetails.email);
    formData.append("phoneNum", formDetails.phoneNum);
    formData.append("message", formDetails.message);

    xhr.timeout = 20000;
    xhr.ontimeout = function (error) {
      console.log("Timed out while submitting Contact us request. ", error);
      setIsLoading(false);
      setMsgBoxHeader("Error!");
      setMsgBoxMsg(
        "Could not submit your request. Please try again later or contact us on this phone number 613-692-2999."
      );
      setShowMsgBox(true);
    };

    xhr.onerror = function (error) {
      console.log(
        "An error occured while submitting Contact Us request. ",
        error
      );
      setIsLoading(false);
      setMsgBoxHeader("Error!");
      setMsgBoxMsg(
        "Could not submit your request. Please try again later or contact us on this phone number 613-692-2999."
      );
      setShowMsgBox(true);
    };

    xhr.onreadystatechange = function () {
      //Call a function when the state changes.
      if (xhr.readyState === 4 && xhr.status === 200) {
        setIsLoading(false);
        setMsgBoxHeader("Submitted Successfully!");
        setMsgBoxMsg("Your message has been submitted successfully.");
        setShowMsgBox(true);
        resetContactForm();
      }
    };
    // send the request
    xhr.send(formData);
  };

  const closeDialog = () => {
    // console.log("showDialog: ", showMsgBox);
    setShowMsgBox(false);
  };

  return (
    <div className="contactus-container">
      <Helmet>
        <title>Contact Us | Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="Call us at 613-692-2999. Visit us at 5556 Manotick Main St Unit 5, Manotick, Ontario K4M1A4"
        />
      </Helmet>
      <div className="contactus-title">
        <p className="contactus-title-txt">Contact Us </p>
      </div>

      <div className="content-container">
        {<LoadingSpinner show={isLoading} />}
        {
          <SubmitMessageBox
            show={showMsgBox}
            okClickHandler={closeDialog}
            msgBoxHeader={msgBoxHeader}
            msgBoxMsg={msgBoxMsg}
          />
        }
        <div className="loc-info-container">
          <div className="loc-info-title">
            <p>Our Location</p>
          </div>
          <div className="loc-info-address">
            <span>
              <ExternalLink href="https://www.google.com/maps/place/Manotick+Main+Pharmacy+-+Rx+Pharmachoice/@45.2241607,-75.6857751,17z/data=!4m5!3m4!1s0x4ccde5d7ab72289b:0x95468bc4bc361927!8m2!3d45.2241607!4d-75.6835864">
                <FaMapMarkerAlt className="location-icon" />
              </ExternalLink>
            </span>
            <p>
              5556 Manotick Main St Unit 5, <br />
              Manotick, Ontario K4M1A4
            </p>
          </div>
          <div className="loc-info">
            <p>
              Tel: 613-692-2999 <br />
              Fax: 613-692-3056
            </p>
          </div>
        </div>

        <div className="contact-form-container">
          <form onSubmit={onSubmitContact}>
            <div className="contact-form-row">
              <div className="contact-form-col">
                <label className="column-label">First Name</label>
                <input
                  type="text"
                  className="column-input"
                  name="firstName"
                  value={formDetails.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="contact-form-col">
                <label className="column-label">Last Name</label>
                <input
                  type="text"
                  className="column-input"
                  name="lastName"
                  value={formDetails.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="contact-form-row">
              <div className="contact-form-col">
                <label className="column-label contact-required">Email</label>
                <input
                  type="email"
                  className="column-input"
                  name="email"
                  value={formDetails.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="contact-form-row">
              <div className="contact-form-col">
                <label className="column-label">Phone Number</label>
                <input
                  type="tel"
                  className="column-input"
                  name="phoneNum"
                  value={formDetails.phoneNum}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="contact-form-row">
              <div className="contact-form-col">
                <label className="column-label contact-required">Message</label>
                <textarea
                  className="column-textarea"
                  name="message"
                  value={formDetails.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="contact-form-row">
              <input
                type="submit"
                value="Submit"
                className="submit-button"
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
