import React from "react";
import "./LoadingSpinner.css";

export default function LoadingSpinner({ show }) {
  var displayProp = show ? "flex" : "none";
  return (
    <div className="spinner-container" style={{ display: displayProp }}>
      <center>
        <div className="loading-spinner"></div>
      </center>
    </div>
  );
}
