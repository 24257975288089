import React from "react";
import "./SubmitMessageBox.css";

const SubmitMessageBox = ({
  show,
  okClickHandler,
  msgBoxHeader,
  msgBoxMsg,
}) => {
  var displayProp = show ? "flex" : "none";

  return (
    <div className="message-box-container" style={{ display: displayProp }}>
      <center>
        <div className="message-box">
          <div className="message-box-header">{msgBoxHeader}</div>
          <div className="message-box-msg">{msgBoxMsg}</div>
          <div className="message-box-options">
            <button onClick={okClickHandler} className="message-box-btn">
              Ok
            </button>
          </div>
        </div>
      </center>
    </div>
  );
};

export default SubmitMessageBox;
