import "./Service.css";
import { useEffect } from "react";
import { services } from "./ServiceData";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(service.title);
    document.title = service.title + " | Manotick Main Pharmacy";
  });

  const location = useLocation();
  const { name } = location.state;

  const serviceName = name["serviceName"];
  // console.log("service name: ", serviceName);
  var service = services[serviceName];

  var hasImage = service.image !== "";
  var image = service.image;

  return (
    <div className="service-container">
      <Helmet>
        <title>Our Services | Manotick Main Pharmacy</title>
        <meta
          name="description"
          content="We provide Compliance pack, Compression stocking, Medication review and home and health care products, etc. "
        />
      </Helmet>
      <div className="service-top-container">
        {hasImage && (
          <div className="service-img-container">
            <img
              src={require(`${image}`)}
              alt={service.title}
              className="service-img"
            />
          </div>
        )}

        <div className="service-title-intro-container">
          <div className="service-title">{service.title}</div>
          <div className="service-intro">{service.intro}</div>
        </div>
      </div>
      <div className="service-page-info">{service.pageInfo}</div>
    </div>
  );
};

export default Service;
