import "./Navbar.css";
import { navItems } from "./NavItems";
import { prescMenuItems } from "./NavItems";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import { useState, useEffect } from "react";
import { ExternalLink } from "react-external-link";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
  const [prescDropdown, setPrescDropdown] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isCompounding, setIsCompounding] = useState(false);
  const serviceName = "compounding";

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const onMouseEnterPresc = () => {
    setPrescDropdown(true);
  };

  const onMouseLeave = () => {
    setPrescDropdown(false);
  };

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const onCompoundingClick = () => {
    setIsCompounding(true);
    toggleNav();
  };

  const onNavItemClick = () => {
    setIsCompounding(false);
    toggleNav();
  };

  return (
    <>
      <div className="navbar">
        {(toggleMenu || screenWidth > 1100) && (
          <div className="navbar-items">
            {navItems.map((item) => {
              if (item.title === "PRESCRIPTIONS") {
                return (
                  <div
                    className="nav-item"
                    onMouseEnter={onMouseEnterPresc}
                    onMouseLeave={onMouseLeave}
                    key={item.id}
                    onClick={onNavItemClick}
                  >
                    <NavLink
                      to={item.path}
                      className={({ isActive }) =>
                        isActive ? "nav-link-active" : `${item.cName}`
                      }
                    >
                      <div>{item.title}</div>
                    </NavLink>
                    {prescDropdown && <Dropdown menuItems={prescMenuItems} />}
                  </div>
                );
              }

              if (item.title === "COMPOUNDING") {
                return (
                  <div
                    className="nav-item"
                    key={item.id}
                    onClick={onCompoundingClick}
                  >
                    <NavLink
                      to={item.path}
                      state={{ name: { serviceName } }}
                      className={({ isActive }) =>
                        isActive && isCompounding
                          ? "nav-link-active"
                          : `${item.cName}`
                      }
                    >
                      <div>{item.title}</div>
                    </NavLink>
                  </div>
                );
              }

              return (
                <div
                  className="nav-item"
                  key={item.id}
                  onClick={onNavItemClick}
                >
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      isActive ? "nav-link-active" : `${item.cName}`
                    }
                  >
                    <div>{item.title}</div>
                  </NavLink>
                </div>
              );
            })}
            <ExternalLink href="https://www.bookmytest.ca/6136922999">
              <button className="btn" onClick={toggleNav}>
                BOOK AN APPOINTMENT
              </button>
            </ExternalLink>
          </div>
        )}

        <div onClick={toggleNav} className="navbar-btn">
          {/* Menu */}
          <FaBars />
        </div>
      </div>
    </>
  );
};

export default Navbar;
