import "./PrescBox.css";
import { Link } from "react-router-dom";

const InfoBox = ({ btnName, linkTo, serviceName, ...props }) => {
  return (
    <div className={`${props.boxClassName}`}>
      <div className={`${props.imgBoxClass}`}>
        <div className={`${props.imgDivClass}`}>
          <img src={props.img} alt={btnName} className={`${props.imgClass}`} />
        </div>
      </div>
      <Link to={linkTo} state={{ name: { serviceName } }}>
        <button className={`${props.btnClass}`}>{btnName}</button>
      </Link>
      {props.hasIntro && (
        <div className={`${props.introClass}`}>{props.intro}</div>
      )}
    </div>
  );
};

export default InfoBox;
